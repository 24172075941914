import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

// third-party module imports
import { ToastrModule } from 'ngx-toastr';

// custom module imports
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { LayoutModule } from '@app/layout/layout.module';


// custom component imports
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import {
  ApiService,
  AuthService
} from '@app/shared/services';
import {
  AuthGuard
} from '@app/core/guards';
import { AppRoutes } from './app.routing';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { useHash: false }),

    // third-party modules
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),

    // core & shared
    CoreModule,
    SharedModule,

    // layout
    LayoutModule
  ],
  providers: [
    ApiService,
    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
