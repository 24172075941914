import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpParams } from '@angular/common/http';

// third party package import
import {timer as observableTimer, interval as observableInterval, of as observableOf,  Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';
import {distinctUntilChanged, map, mergeMap} from 'rxjs/operators';

// custom service import
import { StorageService } from './storage.service';
import { ApiService } from './api.service';

// custom model import
import { User } from '../models';

@Injectable()
export class AuthService {

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private currentUserSubject =  new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  public refreshSubscription: any;
  cachedRequests: Array<HttpRequest<any>> = [];

  constructor (
    private apiService: ApiService,
    private storage: StorageService,
    private router: Router,
  ) {}

  attemptAuth(type, credentials): Observable<any> {
    const creds = 'grant_type=password&password=' + encodeURIComponent(credentials['password']) + '&username=' + encodeURIComponent(credentials['username']);
    return this.apiService.authPost('token/', creds).pipe(
      map(data => {
          const expiresAt: any = data.expires_in * 1000 + new Date().getTime();
          this.setAuth(data);
          this.storage.store('exp_delay', data.expires_in.toString());
          this.storage.store('exp_date', expiresAt);
          this.storage.store('refresh_token', data.refresh_token);

          return data;
        }
      ));
  }

  getToken(): String {
    return this.storage.retrieve('authToken');
  }

  saveToken(token: String) {
    this.storage.store('authToken', token);
  }

  destroyToken() {
    this.storage.clear('authToken');
  }

  setAuth(data: any) {
    // Save JWT sent from server in localstorage
    this.saveToken(data.access_token);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);

    this.getUserInfo();
    this.setNavigation();
    this.getShipmentStore();
  }

  getUserInfo() {
    if (!this.storage.retrieve('user')) {
      this.apiService.get('auth-user')
      .subscribe(response => {
        if (!response['settings']) {
          response['settings'] = {};
        }
        this.storage.store('user', response);
        // Set current user data into observable
        this.currentUserSubject.next(response);
      });
    } else {
      const user = this.storage.retrieve('user');
      this.currentUserSubject.next(user);
    }
  }

  /** Get Shipment Stores*/
  getShipmentStore() {
    // const url = 'shipment_store';
    // return this.apiService.get(url).pipe(
    //   map(
    //     data => {
    //       this.storage.store('shipmentStore', data);
    //     }
    //   ));

    if (!this.storage.retrieve('shipment_store')) {
      this.apiService.get('shipment_store')
      .subscribe(response => {
        this.storage.store('shipment_store', response.results);
        console.log('Store ::::::', this.storage);
       });
    }
  }

  isTokenExpired(token: String, offsetSeconds?: number): boolean {
    const expiresAt = parseInt(this.storage.retrieve('exp_date'), 10);
    return Date.now() > expiresAt;
  }

  collectFailedRequest(request): void {
    this.cachedRequests.push(request);
    console.log(this.cachedRequests)
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.revokeToken();
    this.destroyToken();
    this.storage.clearAll();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Unschedule the token refresh
    this.unscheduleRefresh();
  }

  unscheduleRefresh() {
    // Unsubscribe from the refresh
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  revokeToken() {
    const token = this.storage.retrieve('authtoken');
    const creds = 'token=' + encodeURIComponent(token);

    this.apiService.authPost('revoke_token/', creds)
      .subscribe(response => {});
  }

  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.getToken()) {
      const token = this.getToken();
      const tokenExpired = this.isTokenExpired(token);
      if (tokenExpired) {
        // Remove any potential remnants of previous auth states
        this.purgeAuth();
      } else {
        const temp = {'access_token' : this.getToken()};
        this.setAuth(temp);
        this.startupTokenRefresh();
      }
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  startupTokenRefresh() {
    console.log('startupTokenRefresh');
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    const idToken = this.getToken();
    const tokenExpired = idToken ? this.isTokenExpired(idToken) : true;
    if (!tokenExpired) {
      const source = observableOf(idToken).pipe(mergeMap(
        token => {
        // Get the expiry time to generate

        const now: any = new Date().valueOf();

        // tslint:disable-next-line:radix
        const date: any =  this.storage.retrieve('exp_date') ? parseInt(this.storage.retrieve('exp_date')) : 0;
        const temp = new Date(date).getTime();
        const refreshTokenThreshold = 10; // seconds

        // a delay in milliseconds
        let delay: any = temp - now;
        (delay < refreshTokenThreshold ) ? delay = 1 : delay = delay - refreshTokenThreshold;

        // Use the delay in a timer to
        // run the refresh at the proper time
        return observableTimer(delay);
      }));

       // Once the delay time from above is
       // reached, get a new JWT and schedule
       // additional refreshes
       source.subscribe(() => {
        this.getNewJwt().subscribe(
          (res) => {
          this.scheduleRefresh();
          },
          (error) => {
            this.purgeAuth();
            console.log('-> Refresh error:' + JSON.stringify(error));
            this.router.navigateByUrl('/login');
          }
        );
       });
    }
  }

  scheduleRefresh() {
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    const idToken = this.getToken();
    const source = observableOf(idToken).pipe(mergeMap(
      token => {
        // tslint:disable-next-line:radix
        let delay = parseInt(this.storage.retrieve('exp_delay'));
        const refreshTokenThreshold = 10;
        delay = (delay - refreshTokenThreshold) * 1000;
        // tslint:disable-next-line:radix
        return observableInterval(delay);
      }));

    this.refreshSubscription = source.subscribe(() => {
      this.getNewJwt().subscribe(
        (res) =>  {},
        (error) => {
          this.purgeAuth();
          console.log('-> Refresh error:' + JSON.stringify(error));
          this.router.navigateByUrl('/login');
        }
      );
    });
  }

  getNewJwt() {
    const refreshToken = this.storage.retrieve('refresh_token');
    const creds = 'grant_type=refresh_token&refresh_token=' + encodeURIComponent(refreshToken);

    return this.apiService.authPost('token/', creds).pipe(
      map(
        data => {
          this.setAuth(data);
          this.storage.store('exp_delay', data.expires_in.toString());
          const expiresAt = (data.expires_in * 1000) + new Date().getTime();
          this.storage.store('exp_date', expiresAt);
          this.storage.store('refresh_token', data.refresh_token);
        }
      ));
  }

  /** Get navigation data   */
  getNavigation(): Observable<any> {
    const url = `navigations`;
    return this.apiService.get(url).pipe(
      map(
        data => data
      ));
  }

  buildNavigation (navigations) {
    const leftNavigation = {};
    navigations.forEach((topNav) => {
      leftNavigation[topNav.slug] = [];
      if (topNav['children']['left']) {
        topNav['children']['left'].forEach((childNav) => {
          leftNavigation[topNav.slug].push(childNav);
        });
      }
    });
    this.storage.store('navigation', navigations, 'session');
    this.storage.store('leftNavigation', leftNavigation, 'session');
  }

  setNavigation() {
    if (!this.storage.retrieve('navigation', 'session')) {
      this.apiService.get('navigations').subscribe(response => {
        this.buildNavigation(response['top']);
      });
    } else {
      const navigations = this.storage.retrieve('navigation', 'session');
      this.buildNavigation(navigations);
    }
  }

}
