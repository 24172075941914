export const environment = {
  production: true,
  // apiUrl: 'https://sif-api.beta.fayastage.com/api/v1/portal/',
  // authUrl: 'https://sif-api.beta.fayastage.com/oauth2/',
  apiUrl: 'https://sif-api-uat.fayastage.com/api/v1/portal/',
  authUrl: 'https://sif-api-uat.fayastage.com/oauth2/',
  clientId: 'pFNmDJpWCWQvH1NYVyHWgz9SdqLL3sDPDw5Fuh5H',
  clientSecret: 'YST5Hl8jgbrHqBnvh0CzBSeXIbw89wGn0tqpWUDGIiBk1nWmsMluvcSrjTmmMDCA97IjpuP74YJAadBsDhHtODc1aB5DgMCzmYbRFwhA5RcRekMOgFUzGc57OeE522pD',
  tableRowLimit: '15',
};
